export interface Employer {
  business_relationship: string;
  comment: string;
  company_key: string | null;
  created_at: string;
  created_by: string;
  customer_key: string | null;
  email: string | null;
  id: number;
  key_account: string | null;
  name: string;
  newsletter: number;
  parent_id: number;
  phone: string;
  pseudonym: string;
  show_branch_number: number;
  status: string;
  updated_at: string;
  updated_by: string;
  v_number: string | null;
  visible_for_ems: boolean;
  visible_for_sug: boolean;
  website: string | null;
}
