import { Address } from '@ems/api';

export interface ContactMedium {
  id: number;
  contact_id: number;
  medium_id: number;
  relation: string;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  company_id: number;
  primary: boolean;
  contact: Contact;
  medium: Medium;
  company: Company;
}

interface Contact {
  id: number;
  salutation: string;
  title: string | null;
  first_name: string;
  last_name: string;
  position: string;
  email: string;
  phone: string;
  employer: Company;
  status: string;
}

interface Medium {
  id: number;
  name: string;
  display_name: string;
  medium_key: number;
  mediaCategory: MediaCategory;
  company_id: number;
  company: {
    id: number;
    name: string;
    pseudonym: string;
  };
}

interface MediaCategory {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  media_genre: string;
  media_type: string;
  createdBy: string | null;
  updatedBy: string | null;
}

interface Company {
  id: number;
  name: string;
  pseudonym: string;
  business_relationship: string;
  v_number: number | null;
  parent_id: number | null;
  mailingAddress: Address;
}
