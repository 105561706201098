import { Address, ModifiedByUser } from '@ems/api';

export interface Media {
  id: number;
  created_at: string;
  updated_at: string;
  company_id: number;
  medium_key: number;
  name: string;
  display_name: string;
  discontinued_from: string | null;
  discontinued_from_comment: string | null;
  discontinued_until: string | null;
  discontinued_until_comment: string | null;
  minimum_circulation: number;
  minimum_order: string;
  target_price_wednesday_net_per_mille: string;
  target_price_saturday_net_per_mille: string;
  price_comment: string | null;
  min_booking_unit: string;
  comment: string | null;
  status: string;
  blocking_notice: string;
  created_by: string | null;
  updated_by: string;
  badges: any[];
  contactRelations: any[];
  mediaCategory: MediaCategory;
  rating: Rating;
  datasourceDistributionArea: DatasourceDistributionArea;
  publicationDay: PublicationDay;
  releaseSchedule: ReleaseSchedule;
  createdBy: ModifiedByUser | null;
  updatedBy: ModifiedByUser | null;
  company: Company;
}

interface MediaCategory {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  media_genre: string;
  media_type: string;
  createdBy: ModifiedByUser | null
  updatedBy: ModifiedByUser | null
}

interface Rating {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string;
  name: string;
  rating: number;
}

interface DatasourceDistributionArea {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string;
  name: string;
  createdBy: ModifiedByUser
  updatedBy: ModifiedByUser;
}

interface PublicationDay {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  createdBy: ModifiedByUser;
  updatedBy: ModifiedByUser;
}

interface ReleaseSchedule {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string;
  name: string;
  createdBy: ModifiedByUser;
  updatedBy: ModifiedByUser;
}

interface Company {
  id: number;
  name: string;
  pseudonym: string;
  business_relationship: string;
  v_number: number;
  mailingAddress: Address;
}
