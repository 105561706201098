export interface MediaCategory {
  createdBy: string;
  created_at: string;
  created_by: string;
  id: number;
  media_genre: string;
  media_type: string;
  updatedBy: number;
  updated_at: string;
  updated_by: string;
}

interface Company {
  id: number;
  name: string;
  pseudonym: string;
}

interface Medium {
  company: Company;
  company_id: number;
  display_name: string;
  id: number;
  mediaCategory: MediaCategory;
  medium_key: number;
  name: string;
}
export interface MediumPriceManagement {
  created_at: string;
  created_by: string;
  id: number;
  medium: Medium;
  price_management_id: number;
  updated_at: string;
  updated_by: string;
}
