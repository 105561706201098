import { CompanyPriceManagement } from './company-price-management.interface';

export interface PriceManagementWeightSet {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  price_management_id: number;
  companyPriceManagements: CompanyPriceManagement[];
  additionalWeightSurcharges: AdditionalWeightSurcharge[];
  weightRanges: WeightRange[];
}

export interface AdditionalWeightSurcharge {
  id: number;
  per_weight: number;
  up_to_weight: number;
  surcharge_purchase_price: string;
  surcharge_retail_price: string;
  price_management_weight_set_id: number;
  surcharge_purchase_price_netto_1: number;
  surcharge_purchase_price_netto_2: number;
  surcharge_retail_price_netto_1: number;
  surcharge_retail_price_netto_2: number;
}

export interface WeightRange {
  id: number;
  up_to_weight: number;
  purchase_price: string;
  retail_price: string;
  price_management_weight_set_id: number;
  purchase_price_netto_1: number;
  purchase_price_netto_2: number;
  retail_price_netto_1: number;
  retail_price_netto_2: number;
}

export interface WeightSetCreate {
  price_management_id: number;
  company_price_management_ids: number[];
  weight_ranges: WeightSetWeightRange[];
  additional_weight_surcharges?: WeightSetAdditionalWeightSurcharge[];
}

export interface WeightSetWeightRange {
  up_to_weight: number;
  purchase_price: number;
  retail_price: number;
}

export interface WeightSetAdditionalWeightSurcharge {
  per_weight: number;
  up_to_weight: number;
  surcharge_purchase_price: number;
  surcharge_retail_price: number;
}
