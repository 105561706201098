import { UpdateBy } from './updated-by.interface';
import { Employer } from './employer.interface';

export interface Contact {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  title: string | null;
  first_name: string;
  last_name: string;
  position: string;
  phone: string | null;
  mobile: string | null;
  fax: string | null;
  email: string;
  cost_centre: string | null;
  status: string;
  offer_by_email: 0 | 1;
  salutation: string;
  sync_key: string | null;
  employer: Employer;
  consents?: Consent[];
  updatedBy?: UpdateBy;
  comment?: string;
}

export interface Consent {
  channel: string;
  consent: number;
  contact_id: number;
  created_at: string;
  created_by: string;
  datetime: string;
  id: number;
  source: string;
  updated_at: string;
  updated_by: string;
}

interface Company {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  parent_id: number;
  customer_key: string | null;
  status: string;
  name: string;
  pseudonym: string;
  v_number: string | null;
  website: string | null;
  comment: string | null;
  phone: string | null;
  email: string | null;
  newsletter: number;
  key_account: string | null;
  business_relationship: string;
  show_branch_number: number;
}

interface CompanyRelation {
  id: number;
  company_id: number;
  contact_id: number;
  relation: string;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  company: Company;
}
