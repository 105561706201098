import { CompanyPriceManagement } from './company-price-management.interface';
import { UpdateBy } from './updated-by.interface';

export interface PriceManagementAdvertisingType {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  advertising_category_id: number;
  advertisingCategory: PriceManagementAdvertisingCategory;
}

export interface PriceManagementAdvertisingCategory {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
}

interface MediaCategory {
  createdBy: string;
  created_at: string;
  created_by: string;
  id: number;
  media_genre: string;
  media_type: string;
  updatedBy: string;
  updated_at: string;
  updated_by: string;
}

export interface PriceManagementMedia {
  id: number;
  company_id: number;
  display_name: string;
  name: string;
  medium_name: string;
  mediaCategory: MediaCategory;
  company: MediaCompany;
}

export interface MediaCompany {
  id: number;
  name: string;
  pseudonym: string;
}

export interface PriceManagement {
  id: number;
  parent: PriceManagement;
  advertisingTypes: PriceManagementAdvertisingType[];
  advertising_categories_string: string;
  agreement_valid_from: string;
  agreement_valid_until: string;
  agreementValidUntilInitially: string;
  annual_bonus_unit: string;
  annual_bonus_value: string;
  automatic_renewal: number;
  comment: string;
  companyPriceManagements: CompanyPriceManagement[];
  contact: PriceManagementContact;
  createdBy: string;
  created_at: string;
  created_by: string;
  currency_code: string;
  discount_in_kind: string;
  has_objection: boolean;
  media: PriceManagementMedia[];
  name: string;
  owner: string;
  objection_date: string;
  objection_period: number;
  objection_possible_until: string;
  order_comment: string;
  renewal_interval: number;
  status: string;
  updatedBy: UpdateBy;
  updated_at: string;
  updated_by: string;
}

export interface PriceManagementContact {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  title: string;
  first_name: string;
  last_name: string;
  position: string;
  phone: string;
  mobile: string | null;
  fax: string | null;
  email: string;
  cost_centre: string | null;
  status: string;
  offer_by_email: number;
  salutation: string;
  sync_key: string | null;
}
