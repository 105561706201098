export interface Address {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  addressable_id: number;
  addressable_type: string;
  address_type: string;
  street: string;
  house_number: string;
  addition: string;
  postcode: string;
  city: string;
  district: string;
  country_code: string;
  latitude: number | null;
  longitude: number | null;
  street_house_number: string;
  postcode_city: string;
  country: Country;
}

interface Country {
  code: string;
  name_en: string;
  name_de: string;
}
