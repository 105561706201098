import { ModifiedByUser } from './misc.interface';

export interface AdvertisingType {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  advertising_category_id: number;
  createdBy: ModifiedByUser | null;
  updatedBy: string | null;
}
