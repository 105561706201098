import { ModifiedByUser } from './misc.interface';

interface AdvertisingType {
  id: number;
  name: string;
  advertising_category_id: number;
}

export interface AdvertisingCategory {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  createdBy: ModifiedByUser | null;
  updatedBy: ModifiedByUser | null;
  advertisingTypes: AdvertisingType[];
  requires_weight_set: number;
}
