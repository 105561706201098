import { ModifiedByUser } from './misc.interface';
import { Address } from './address.interface';

interface Company {
  id: number;
  name: string;
  pseudonym: string;
  business_relationship: string;
  v_number: string | null;
  mailingAddress: Address;
}

export interface CalculationParameterCompanyPriceManagement {
  id: number;
  company: Company;
  section: any | null;
}

export interface CalculationParameter {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  addition: string | null;
  purchase_value_type: string;
  retail_price: string;
  discountable: number;
  ae_capable: number;
  weight: number;
  comment: string;
  purchase_price: string;
  retail_value_type: string;
  price_management_id: number;
  parameter_type: string;
  parameter_sub_type: string;
  createdBy?: ModifiedByUser | null;
  updatedBy?: ModifiedByUser | null;
  companyPriceManagements: CalculationParameterCompanyPriceManagement[];
}

