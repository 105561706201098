import { BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class MediumPriceManagementService extends BaseHttpService {

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }
  getMediumRelations(priceManagementId?: number) {
    let queryParams = {};
    if (priceManagementId) {
      queryParams = {
        ...queryParams,
        'filter[price_management_id][eq]': priceManagementId
      }
    }

    return this.getAll('medium-price-management', queryParams);
  }

  deleteRelation(id: number) {
    return this.delete(`medium-price-management/${id}`);
  }

  createRelation(price_management_id: number, medium_id: number) {
    return this.post('medium-price-management', {
      price_management_id,
      medium_id,
    });
  }
}
