export interface DropdownOption {
  label: string;
  value: number | string | undefined;
}

export interface ModifiedByUser {
  given_name: string;
  family_name: string;
  preferred_username: string;
}

export interface Currency {
  value: string;
  label: string;
  symbol: string
}
