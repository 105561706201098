import { BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class CompanyMediumService extends BaseHttpService {

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getCompanyMedium(companyId?: string) {
    let queryParams = {};
    if (companyId) {
      queryParams = {
        ...queryParams,
        'filter[company_id][eq]': companyId
      }
    }

    return this.getAll('company-medium', queryParams).pipe(
      map((res: any[]) => res.map((res: any) => res))
    );
  }
}
