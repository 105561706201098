export const Favicons = {
  Base: 'favicon.ico',
  Sug: 'favicon-sug.ico',
  Base16: 'favicon-16x16.png',
  Sug16: 'favicon-sug-16x16.png',
  Base32: 'favicon-32x32.png',
  Sug32: 'favicon-sug-32x32.png',
};

export const UmlautMap: {[key: string]: string} = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};
