import {Address, Contact, ModifiedByUser, Section} from '@ems/api';

export interface Branch {
  id: number;
  company_id: number;
  name: string;
  branch_key: number;
  branch_key_customer_2: string;
  branch_key_customer: string;
  region: string;
  section: Section;
  section_id: number;
  status: string;
  visitingAddress: Address;
  regionalManager: Contact;
  areaSalesManager: Contact;
  storeManager: Contact;
  opening_date: Date | null;
  closing_date: Date | null;
  created_at: string;
  updated_at: string;
  created_by: any;
  updated_by: any;
  createdBy?: ModifiedByUser | null;
  updatedBy?: ModifiedByUser | null;
  visible_for_ems: boolean;
  visible_for_sug: boolean;
}

