import { ModifiedByUser } from './misc.interface';

export interface ReleaseSchedules {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  createdBy: ModifiedByUser | null;
  updatedBy: ModifiedByUser | null;
}
